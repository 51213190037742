import {
    CenteredContentWrapper,
    device,
    EmphasizedText,
    Quote,
    Subheading,
    TextToutFullwidth,
    ThumbnailHeader,
} from "@xolvio/xolvio-ui";
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import {MoveHack, ToutContent, ToutHeader, ToutTextBody, ToutWrapper} from "../../components/Services";
import {LayoutDefault} from "../../components/LayoutDefault";
import {HeroBackgroundImage} from "../../components/HowWeWork";
import {ContentWrapper} from "quality-faster/src/components/LandingPage/ContentWrapper";
import {SectionHeader, TextBody} from "@xolvio/xolvio-ui/src"
import styled from 'styled-components';
import {AlternateBackground, ToutRow} from "../../components/Homepage";
import {contactUsFooterProps} from '../../pages-content/homepage'

const Page = ({data}) => {
    return (
        <>
            <ToutWrapper>
                <ToutContent>
                    <ToutHeader>
                        <EmphasizedText text={"Volkswagen of America"} highlight={"aaaa"}/>
                    </ToutHeader>
                    <ToutTextBody style={{textAlign: 'center'}}>{"Car-Net infrastructure"}</ToutTextBody>
                </ToutContent>
            </ToutWrapper>

            <MoveHack top={"150px"}/>

            {/*<CenteredContentWrapper>*/}
            {/*    <Quote body={"Everything should be made as simple as possible, but not simpler."} signature={"Albert Einstein"}/>*/}
            {/*</CenteredContentWrapper>*/}

            <HeroBackgroundImage
                fluid={data.howItWorksBg.childImageSharp.fluid}
                durationFadeIn={200}
                style={{
                    position: "absolute", // this neeeds to be set here
                    width: "200%",
                    height: "320px",
                }}
            />

            <CenteredContentWrapper style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "-50px"}}>
                <SectionHeaderStyled>
                    Connected car applications cloud infrastructure
                </SectionHeaderStyled>
                <StyledTextBody>
                    Embracing digital disruption in the automotive industry, Volkswagen of America was about to enter
                    the connected car market with Car-Net, a connected car services suite. Xolvio joined the project
                    to work in tandem with Volkswagen’s team and alongside another development partner, with the task
                    of creating a cloud infrastructure to host the Car-Net platform. Facing a break-neck deadline of 8
                    weeks, the need for a perfectly reliable high-availability environment, and the security team’s
                    stringent requirements, we immediately established a cross-department leadership group to be able
                    to move quickly. Our work on the highly complex solution’s cloud infrastructure helped reshape some
                    of the application architecture decisions to increase its automatability and quality. By completely
                    separating production from lower environments, enabling the creation of on-demand environments, as
                    well introducing CI/CD pipelines and automated testing, we empowered Volkswagen and their
                    development partner to confidently work at lightning speed, while maintaining ultra high quality.
                    In the end, the Car-Net platform was perfectly operational as per the schedule.
                </StyledTextBody>
            </CenteredContentWrapper>

            <MoveHack top={"50px"}/>

            <CenteredContentWrapper id="howWeWork">
                <ContentWrapper>
                    <Subheading style={{ textAlign: "center" }}>
                        {"THE CHALLENGE"}
                    </Subheading>
                    <SectionHeader style={{maxWidth: "1200px", textAlign: "center"}}>
                        {"Creating the perfect cloud infrastructure in no time"}
                    </SectionHeader>
                    <AlignToLeftOnMobileSolutionParagraph>
                        {"With the introduction of the Car-Net connected car services suite, Volkswagen’s aim was to bring the convenience of digital capabilities to their customers. While the Car-Net platform itself was already developed, Volkswagen needed to create a high-availability, perfectly reliable cloud infrastructure to host it, which presented a number of challenges:"}
                    </AlignToLeftOnMobileSolutionParagraph>
                    <MoveHack top={"50px"}/>

                    <ToutRow style={{justifyContent: "center"}}>
                        <div>
                            <ThumbnailHeader style={{ margin: "auto" }}>
                                {"Looming deadline"}
                            </ThumbnailHeader>
                            <TextBody style={{ margin: "auto", textAlign: "left" }}>
                                {"Due to unforeseen circumstances Volkswagen lost a vendor responsible for delivering the Car-Net infrastructure, which left them with only 8 weeks to develop it from scratch."}
                            </TextBody>
                        </div>
                        <div>
                            <ThumbnailHeader style={{ margin: "auto" }}>
                                {"AWS migration"}
                            </ThumbnailHeader>
                            <TextBody style={{ margin: "auto", textAlign: "left" }}>
                                {"To make things even more challenging, part of Volkswagen’s development partner’s work needed to be ported over to AWS. While this had to be done quickly, it was crucial the solution’s quality would not be compromised."}
                            </TextBody>
                        </div>

                        <div>
                            <ThumbnailHeader style={{ margin: "auto" }}>
                                {"Stringent security & performance requirements"}
                            </ThumbnailHeader>
                            <TextBody style={{ margin: "auto", textAlign: "left" }}>
                                {"The Car-Net platform was required to host connected car applications that would be fronting hundreds of thousands of vehicles, posing the need for an extremely high-availability environment, which also had to be extremely secure."}
                            </TextBody>
                        </div>
                    </ToutRow>
                </ContentWrapper>
            </CenteredContentWrapper>

            <MoveHack top={"50px"}/>

            <CenteredContentWrapper style={{display: "flex", flexDirection: "column"}}>
                <Subheading>
                    {"THE SOLUTION"}
                </Subheading>
                <SectionHeader style={{maxWidth: "100%"}}>
                    {"High quality cloud infrastructure and the production system to develop it quickly\n"}
                </SectionHeader>
                <Solution>
                    <SolutionParagraph>
                        Given the extreme time constraints, we started our engagement with establishing a
                        cross-department leadership group within Volkswagen to be able to move as quickly as possible.
                        This group consisted of people representing numerous departments, including security,
                        infrastructure, management, product, development, quality assurance, as well as external agencies.
                    </SolutionParagraph>
                    <SolutionParagraph>
                        Having the right people on board, we proceeded with employing our proprietary technique
                        called the State of Integration Diagram to get everyone on the same page and create a
                        shared understanding of what needs to be accomplished. Our work included setting up Kubernetes
                        clusters, access control layers, firewalls as per the security team’s requirements, secrets
                        management, application deployment scripts, Jenkins, CI/CD pipelines, as well as Cassandra,
                        MySQL, and Oracle databases.
                    </SolutionParagraph>
                </Solution>
                <Solution>
                    <SolutionParagraph >
                        Dealing with an immensely complex application architecture, we helped reshape some of the
                        architecture decisions to make them more automatable and testable to achieve a higher quality
                        solution. Since time was such a key issue, we did all we could to make sure Volkswagen’s
                        development partner was able to do deployments more predictably and much more frequently.
                    </SolutionParagraph>
                    <SolutionParagraph >
                        As a result of our engagement, the production environment was completely separated from lower
                        environments like QA, staging, or integration. We took great care to properly secure access for
                        the developers and empowered them to set up on-demand environments. By including automated
                        testing in the CI/CD pipelines, we further enabled Volkswagen and their partner to release
                        with absolute confidence and the entire Car-Net platform was good to go as per the schedule.
                    </SolutionParagraph>
                </Solution>
            </CenteredContentWrapper>

            <MoveHack top={"100px"} topMobile={'100px'}/>

            <AlternateBackground>
                <CenteredContentWrapper>
                    <ContentWrapper>
                        <Subheading style={{ textAlign: "center" }}>
                            {"THE OUTCOME"}
                        </Subheading>
                        <SectionHeader style={{maxWidth: "1200px", textAlign: "center"}}>
                            {"Meeting an impossible deadline with flying colors"}
                        </SectionHeader>
                        <OutcomeDescription>
                            {"It’s no exaggeration to say that we came to the rescue when Volkswagen lost their development partner only several weeks before the planned release date. In the end, together with the Volkswagen team we collectively saved the day and the Car-Net platform was safely hosted and ready to front hundreds of thousands of vehicles as per the schedule. Apart from designing a high quality cloud infrastructure for the complex solution, we devised an entire production system that allowed for its lightning fast delivery. Finally, as is often the case with our work, we went beyond our original task and helped reshape some of the solution architecture decisions to improve it for maximum quality."}
                        </OutcomeDescription>
                        <MoveHack top={"150px"}/>

                    </ContentWrapper>
                </CenteredContentWrapper>
            </AlternateBackground>

            <MoveHack top={"-50px"}/>

            <TextToutFullwidth {...contactUsFooterProps} />
        </>
    );
}

const VolkswagenCaseStudy = () => (
    <StaticQuery
        query={graphql`
      query {
        howItWorksBg: file(
          relativePath: { eq: "sectionhow-it-works-bg@2x.png" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
        render={data => {
            return <LayoutDefault>
                <Page data={data} />
            </LayoutDefault>
        }}
    />
);

export default VolkswagenCaseStudy

const SectionHeaderStyled = styled(SectionHeader)`
  max-width: unset;
  text-align: center !important;
  margin-top: 16px;
  margin-bottom: 24px;

  @media ${device.mobile} {
    margin-top: 50px;
    font-size: 28px;
  }
`;

const StyledTextBody = styled(TextBody)`
  text-align: left;
  @media ${device.mobile} {
    max-width: 90%;  
  }
  max-width: 70%;
`

const Solution = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  @media ${device.mobile} {
    flex-direction: column;
      gap: 0;
  }
`

const SolutionParagraph = styled.p`
  flex: 1;
  color: #6b726f;
  font-size: 18px;
  line-height: 1.5;
`

const AlignToLeftOnMobileSolutionParagraph = styled(SolutionParagraph)`
  max-width: 100%;
  font-size: 20px;
  text-align: center;

    @media ${device.mobile} {
        text-align: left;
    }
`


const OutcomeDescription = styled(SolutionParagraph)`
  @media ${device.mobile} {
    max-width: 100%;
  }
  max-width: 80%;
  text-align: left;
  padding-bottom: 100px;
`
